import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import logo from '../components/img/IMG_0028.jpg'
import fpk from '../components/img/fpk2.png'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  .about_wrapper {
    width: 100%;
    /* // max-width: 580px; */
    padding-left: 15px;
    /* background: rgb(242, 241, 226);
    border-radius: 5px;
    box-shadow: 15px 10px 40px -2px rgba(0,0,0,.8); */
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {
    margin-bottom: 10vw;
  }
`

class AboutPage extends React.Component {
  render() {
    return (
      <Layout location="/contacts">
        <LayoutCatalog>
          <Helmet title="Контакты" />
          <div className="about_wrapper">
            <h1>Контакты</h1>
            <p>Телефон: +79787213709</p>
            <p>Почта: krovoopic@mail.ru</p>
          </div>
          <div className="about_images">
            {/* <img src={logo} style={{ maxWidth: 120 }} alt="" /> */}
          </div>
        </LayoutCatalog>
        <Footer />
      </Layout>
    )
  }
}

export default AboutPage
